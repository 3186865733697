import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from '@fadv/web-common';
import { TranslateService } from './framework/i18n/translate.service';

declare let window: any;
declare let $: any;
declare let mitekScienceSDK: any;
const AUTO_CAPTURE_TIME_OUT = 15000;
const FIRST_MESSAGE_DURATION = 1500;
const MITEK_LICENSE_KEY = 'eyJzaWduYXR1cmUiOiJvUmpCSWc0OC81MFFRS0FlTlNsWDZFWllPU0VTbi9aeUhvVjlpUHFEN0NrZ2ZoajhCSEkvdnhtN0JQZnpORTJsdDdVNGpDQTAveitRUWlGaVZaMHJOY1NVaXgva3dnUXdVT3NmQzNGTjdGbDRHQ1lCN0wzNG44SXFzVmkrajdmTG5LOEJXOTk0d3gxTENqVU9sTzRXTExQZi9iSlFPcUQvUTZIMi80LzdjbCtCWDU2ckZOQ05DaFZEOVk2OTBLcnpZWXNoZjlSN0VKdmh3eG5lak1aL3p3a0NYemZNQSt3L0NWenhOWmpiekVianlIbEJyanhqZ0YyZjZYczhMaDVTZW1MbEZDNUNDbUtUNmVGNkw3d1dKdjNSOXREQVh1c0tGYWloYlErYXk4dUVCOEpzTURHdWUvQ2xLb3lCdC9mYmNkbkRDZkNZbkErd2dsamlQTldJd0E9PSIsInNpZ25lZCI6eyJleHBpcnkiOiIyMDI2LTA2LTA1IiwiZmVhdHVyZXMiOlsiZ2VuZXJpYyIsImlkIiwiZmFjZSIsImJhcmNvZGUiXSwiYXBwbGljYXRpb25JZGVudGlmaWVyIjp7IndlYiI6WyIqLmZhZHYubmV0IiwiKi5mYWR2LmNvbSJdfSwiZ3BvIjoiMTZkIiwib3JnYW5pemF0aW9uIjoiTWl0ZWsgU3lzdGVtcyBJbmMuIiwidmVyc2lvbiI6IjEuMCJ9LCJiYXJjb2RlV2ViTGljZW5zZSI6Ilg2UFpkd3c0UXB5Z0pERHNFVmQ2NnAxdk12dE43VExLOTlXVXJyQXZPdkVTZHg5Unp2WllxNzhEKzVhK0h1ZEc0eGkvK0lsRmNhZTc3Mm4wVWVTYWhYS3d0TlprZzNmN0VwU2lSUzc1cjFNeU1tRTBDcU5BYit1a3o3UjNZcitjTUFQanRQRm5sSlJrZVdWcXNwMGZNU2tPRFNldURRdXozeFFPS29FamcwejluQ0FpSUN5WnJtTEFmWXhZL3F0WHJyMFFDMG5uN0pTM1VXRGVQWGpFQWYxM1BkRlB0NHlJblo5aGo2QVlidnk2dHJKdXJoME5nM3k0YXZWT1pZMWYrWHdpMmVGMlorVTdSeTk5TDhla2dqdWJTUE9xcHNqRFZkUE1ab0o2NWR0Q3pOMk9BS2Fva0hFYTBnU0FCV1htb0ZtV0N1dUJPR2J5bU5hQWRGMmZsaTY2UjJsUEVtSEFsZ3FoTWRydWJHOFF0bmZySE94RGw3N2xVK3g0aXZOTUdUMFhpbnl4d1JDVmVzRnQvQzhnZkJYZTYvTDBqaGFlY3JQVEVXYkxuTEYwUmNEMlcyMzNLMUhFTHNBVG5hQ1RBbzdycHYwT2FTRDNidU5qWHlqVFdGeTZJYzVtVWloNjY1WWNIK2NwL1JxM0ZxemJhZkpvMFBuK0x5ekdoeHdiIn0=';
@Component({
  selector: 'lib-mitek-camera',
  templateUrl: './mitek-camera.component.html',
  styleUrls: ['./mitek-camera.component.css']
})
export class MitekCameraComponent implements OnInit {
  @Input() config: any;
  @Output() onCameraNext: EventEmitter<any> = new EventEmitter();
  @Output() onCameraTimeout: EventEmitter<any> = new EventEmitter();
  @Input() resultImage: string;
  misnapError: any;
  attemptTimer = null;
  timer = null;
  recentHint = null;
  manualCaptureHints: any;
  autoCaptureHints: any;
  loading: boolean;
  captureMode: any;
  captureType: any;
  otherMessages: any;
  licenseString: any;
  customMessages: any;

  constructor(public localStorage: LocalStorageService, 
              public _ts: TranslateService) {
    this.config = {};
    this.loading = true;
  }

  ngOnInit() {
    console.log('MitekCameraComponent initializing....')
    let userPrefLanguageCode = this.localStorage.getItem('language_code');
    this._ts.use(userPrefLanguageCode);
    this.timer;
    this.recentHint = null;
    this.attemptTimer = null;
    this.loading = true;
    this.licenseString = MITEK_LICENSE_KEY;
    
    // Convert generic capture mode to mitek literals.
    if (this.config.captureMode == 'Auto'){
      this.captureMode = 'AUTO_CAPTURE';
    } else {
      this.captureMode = 'MANUAL_CAPTURE';
    }

    if (this.config.documentType == 'License' && this.config.captureType == 'DOCUMENTID') {
      if (this.config.documentSide == 'Front') {
        this.captureType = 'DL_FRONT';
      } else {
        this.captureType = 'PDF417_BARCODE';
      }
    } else if (this.config.documentType == 'Passport' && this.config.captureType == 'DOCUMENTID') {
      this.captureType = 'PASSPORT';
    } else if (this.config.captureType == 'SELFIE') {
      this.captureType = 'SELFIE';
    }

    console.log('Mitek sdk version loaded - ', mitekScienceSDK.getVersion());
    
    this.loadMessages();
    
    this.loadCamera();
  }
  
  loadMessages() {
    this.autoCaptureHints = {
      MISNAP_HEAD_OUTSIDE: this._ts.instant('MISNAP_HEAD_OUTSIDE'),
      MISNAP_HEAD_SKEWED: this._ts.instant('MISNAP_HEAD_SKEWED'),
      MISNAP_AXIS_ANGLE: this._ts.instant('MISNAP_AXIS_ANGLE'),
      MISNAP_HEAD_TOO_CLOSE: this._ts.instant('MISNAP_HEAD_TOO_CLOSE'),
      MISNAP_HEAD_TOO_FAR: this._ts.instant('MISNAP_HEAD_TOO_FAR'),
      MISNAP_STAY_STILL: this._ts.instant('MISNAP_STAY_STILL'),
      MISNAP_SUCCESS: this._ts.instant('MISNAP_SUCCESS'),
      MISNAP_STOP_SMILING: this._ts.instant('MISNAP_STOP_SMILING'),
      MISNAP_SMILE: this._ts.instant('MISNAP_SMILE'),
      MISNAP_READY_POSE: this._ts.instant('MISNAP_READY_POSE'),
      NO_FACE_FOUND: this._ts.instant('NO_FACE_FOUND'),
      MITEK_ERROR_GLARE: this._ts.instant('MITEK_ERROR_GLARE'),
      MITEK_ERROR_FOUR_CORNER: this._ts.instant('MITEK_ERROR_FOUR_CORNER'),
      MITEK_ERROR_TOO_DARK: this._ts.instant('MITEK_ERROR_TOO_DARK'),
      MITEK_ERROR_FOCUS: this._ts.instant('MITEK_ERROR_FOCUS'),
      MITEK_ERROR_MRZ_MISSING: this._ts.instant('MITEK_ERROR_MRZ_MISSING'),
      CV_NO_BARCODE_FOUND: this._ts.instant('CV_NO_BARCODE_FOUND'),
      MITEK_ERROR_TOO_FAR: this._ts.instant('MITEK_ERROR_TOO_FAR'),
      MITEK_ERROR_TOO_CLOSE: this._ts.instant('MITEK_ERROR_TOO_CLOSE'),
      MITEK_ERROR_NOT_CENTERED: this._ts.instant('MITEK_ERROR_NOT_CENTERED'),
      MITEK_ERROR_MIN_PADDING: this._ts.instant('MITEK_ERROR_MIN_PADDING'),
      MITEK_ERROR_HORIZONTAL_FILL: this._ts.instant('MITEK_ERROR_HORIZONTAL_FILL'),
      MITEK_ERROR_LOW_CONTRAST: this._ts.instant('MITEK_ERROR_LOW_CONTRAST'),
      MITEK_ERROR_BUSY_BACKGROUND: this._ts.instant('MITEK_ERROR_BUSY_BACKGROUND'),
      MITEK_ERROR_SKEW_ANGLE: this._ts.instant('MITEK_ERROR_SKEW_ANGLE'),
      MITEK_ERROR_PERPENDICULAR_DOCUMENT: this._ts.instant('MITEK_ERROR_PERPENDICULAR_DOCUMENT'),
      CUSTOM_AUTO_TIMEOUT: this._ts.instant('CUSTOM_AUTO_TIMEOUT'),
    };

    this.manualCaptureHints = {
      MITEK_ERROR_FOUR_CORNER: this._ts.instant('GENERAL_MITEK_ERROR_FOUR_CORNER'),
      MITEK_ERROR_TOO_DARK: this._ts.instant('GENERAL_MITEK_ERROR_TOO_DARK'),
      MITEK_ERROR_FOCUS: this._ts.instant('GENERAL_MITEK_ERROR_FOCUS'),
      MITEK_ERROR_GLARE: this._ts.instant('GENERAL_MITEK_ERROR_GLARE'),
      MITEK_ERROR_MIN_PADDING: this._ts.instant('GENERAL_MITEK_ERROR_MIN_PADDING'),
      MITEK_ERROR_HORIZONTAL_FILL: this._ts.instant('GENERAL_MITEK_ERROR_HORIZONTAL_FILL'),
      MITEK_ERROR_SKEW_ANGLE: this._ts.instant('GENERAL_MITEK_ERROR_SKEW_ANGLE'),
      MITEK_ERROR_LOW_CONTRAST: this._ts.instant('MITEK_ERROR_LOW_CONTRAST'),
      MITEK_ERROR_BUSY_BACKGROUND: this._ts.instant('GENERAL_MITEK_ERROR_BUSY_BACKGROUND'),
      MITEK_ERROR_MRZ_MISSING: this._ts.instant('GENERAL_MITEK_ERROR_MRZ_MISSING'),
      CV_NO_BARCODE_FOUND: this._ts.instant('GENERAL_CV_NO_BARCODE_FOUND'),
      IMAGE_SMALLER_THAN_MIN_SIZE: this._ts.instant('GENERAL_IMAGE_SMALLER_THAN_MIN_SIZE'),
      CORRUPT_IMAGE: this._ts.instant('GENERAL_CORRUPT_IMAGE'),
      MISNAP_HEAD_SKEWED: this._ts.instant('GENERAL_MISNAP_HEAD_SKEWED'),
      MISNAP_HEAD_TOO_CLOSE: this._ts.instant('GENERAL_MISNAP_HEAD_TOO_CLOSE'),
      MISNAP_HEAD_TOO_FAR: this._ts.instant('GENERAL_MISNAP_HEAD_TOO_FAR'),
      NO_FACE_FOUND: this._ts.instant('GENERAL_NO_FACE_FOUND'),
      MITEK_ERROR_PERPENDICULAR_DOCUMENT: this._ts.instant('MITEK_ERROR_PERPENDICULAR_DOCUMENT'), 
    };

    this.otherMessages= {
      OTHER_HINT_PLEASE_WAIT : this._ts.instant('OTHER_HINT_PLEASE_WAIT'),
      OTHER_HINT_FILL_GUIDE : this._ts.instant("OTHER_HINT_FILL_GUIDE"),
      OTHER_HINT_NO_IMG_CAPTURED : this._ts.instant("OTHER_HINT_NO_IMG_CAPTURED")
    };
    
    this.customMessages = {
      DL_FRONT: {
        firstMessage: this._ts.instant('DL_FRONT_FIRST_MESSAGE'),
        fourCornerMessage: this._ts.instant('DL_FRONT_FOUR_CORNER_MESSAGE')
      },
      PDF417_BARCODE: {
        firstMessage: this._ts.instant('DL_FRONT_FIRST_MESSAGE'),
        fourCornerMessage: this._ts.instant('DL_FRONT_FOUR_CORNER_MESSAGE')
      },
      PASSPORT: {
        firstMessage: this._ts.instant('PASSPORT_FIRST_MESSAGE'),
        fourCornerMessage: this._ts.instant('PASSPORT_FOUR_CORNER_MESSAGE')
      },
      SELFIE: {
        firstMessage: this._ts.instant('SELFIE_FIRST_MESSAGE'),
        fourCornerMessage: this._ts.instant('SELFIE_FOUR_CORNER_MESSAGE')
      }
    }
  }

  loadCamera(){
    let that = this;
    
    //New code v5.5.0
    mitekScienceSDK.on('SDK_ERROR', function (err) {
      console.log("Sdk Error", err);
      
      if (err.code === 4006) {
        console.log('Invalid license key');
      }
    });
    
    /* Makes a call to component preload for faster response time.
    Also passes in the main SDK license required to do so. */
    const mitekPath = "../../../assets/js/mitekSDK/";
    
    mitekScienceSDK.cmd('COMPONENT_PRELOAD', {
      mitekSDKPath: mitekPath,
      preloadComponents: ['ALL'],
      options: {
        license: that.licenseString
      }
    });
    
    // Auto capture mode
    if (this.config.captureMode == 'Auto') {
      this.initializeAndRunAutoMode();
    } else {
      this.initializeAndRunManualMode();
    }
  }

  initializeAndRunAutoMode(){
    console.log('Initializing Autocapture mode..');
    this.recentHint = null;
    this.attemptTimer = null;
    this.resultImage = null;
    this.misnapError = null;
    let that = this;
    this.loading = true;
    this.captureMode = 'AUTO_CAPTURE';
    
    const mitekPath = "../../../assets/js/mitekSDK/";
    
    // camera started
    mitekScienceSDK.on('CAMERA_DISPLAY_STARTED', function (result) {
      that.onAutoCaptureCameraDisplayStarted(result);
    });
    
    mitekScienceSDK.on('FRAME_PROCESSING_FEEDBACK', function (result) {
      that.onAutoCaptureFrameProcessingFeedback(result);
    });
    
    // Perform steps when camera starts, timeout, etc
    mitekScienceSDK.on('FRAME_PROCESSING_STARTED', function (result){
      that.onAutoCaptureFrameProcessingStarted(result);
    });
    
    // Perform steps once after trying to capture document and result - success/error is returned
    mitekScienceSDK.on('FRAME_CAPTURE_RESULT', function (result){
      that.onAutoCaptureFrameCaptureResult(result);
    });
    
    mitekScienceSDK.on('SDK_ERROR', function (err) {
      that.onAutoCaptureSdkError(err);
    });

    const cmdOptions = {
      documentType: this.captureType,
      mode: this.captureMode,
      mitekSDKPath: mitekPath,
      options: {
        qualityPercent: 80,
        license: this.licenseString
      }
    };

    // Start camera
    mitekScienceSDK.cmd('CAPTURE_AND_PROCESS_FRAME', cmdOptions);
    console.log('MitekCameraComponent Auto loaded....')
  }

  initializeAndRunManualMode(){
    console.log('Initializing Manual mode..');
    // this.recentHint = null;
    // this.attemptTimer = null;
    this.resultImage = null;
    this.misnapError = this.otherMessages['OTHER_HINT_NO_IMG_CAPTURED'];
    let that = this;
    this.captureMode = 'MANUAL_CAPTURE';
    this.loading = true;
    const mitekPath = "../../../assets/js/mitekSDK/";
    
    setTimeout(() => {
      that.loading = false;
    }, 1000);

    mitekScienceSDK.on('SDK_ERROR', function (err) {
      that.onManualSdkError(err);
    });

    // Perform steps when camera starts
    mitekScienceSDK.on('FRAME_PROCESSING_STARTED', function (result){
      that.onManualFrameProcessingStarted(result);
    });

    mitekScienceSDK.on('FRAME_CAPTURE_RESULT', function (result){
      that.onManualFrameCapturedResult(result);
    });

    mitekScienceSDK.on('FRAME_PROCESSING_FEEDBACK', function (result) {
      that.onManualFrameProcessingFeedback(result);
    });

    const cmdOptions = {
      documentType: this.captureType,
      mode: this.captureMode,
      mitekSDKPath: mitekPath,
      options: {
        qualityPercent: 80,
        license: this.licenseString
      }
    };

    // Start camera
    mitekScienceSDK.cmd('CAPTURE_AND_PROCESS_FRAME', cmdOptions);
    console.log('MitekCameraComponent Manual loaded....')
  }

  /**
   * AutoCapture Camera Display Started method
   * Triggers once when livepreview video has started. Use as signal for UX. 
   * Only auto-capture.
   * @param result 
   */
  onAutoCaptureCameraDisplayStarted(result: any){
    // cameraDisplayStarted on auto.
    // show the first initial hint message
    let firstMessage = this.customMessages[this.captureType].firstMessage;
    
    // Hint message command example with new options object
    mitekScienceSDK.cmd('SHOW_HINT', { options: { hintText: firstMessage, hintDuration: FIRST_MESSAGE_DURATION } });
    
    // if (this.captureType === 'SELFIE') {
    //   mitekScienceSDK.cmd('SHOW_HINT', this.otherMessages["OTHER_HINT_PLEASE_WAIT"]);
    // } else {
    //   mitekScienceSDK.cmd('SHOW_HINT', this.otherMessages["OTHER_HINT_FILL_GUIDE"]);
    // }
  }

  /**
   * AutoCapture Frame Processing Feedback method
   * Triggers continuously until a successful capture. Use for hint-messages. 
   * Only auto-capture.
   * @param result 
   */
  onAutoCaptureFrameProcessingFeedback(result: any){
    try {
      this.recentHint = result.key;

      if (this.config.captureType === 'SELFIE') {
        let guideElement = document.getElementById("mitekGuide");
        // turn oval green if head is in guide
        if (result.key === 'MISNAP_SMILE'
          || result.key === 'MISNAP_STOP_SMILING'
          || result.key === 'MISNAP_READY_POSE') {
          guideElement && guideElement.classList.add('active');
          // document.getElementsByClassName('integrator SELFIE')[0].classList.add('FACE_IN_GUIDE');
        } else {
          guideElement && guideElement.classList.remove('active');
          // document.getElementsByClassName('integrator SELFIE')[0].classList.remove('FACE_IN_GUIDE');
        }
        if (this.recentHint !== null) {
          mitekScienceSDK.cmd('SHOW_HINT', this.autoCaptureHints[this.recentHint]);
        }
      } // NOT SELFIE, EVERYTHING ELSE for feedback
      else {
        if (this.recentHint !== null) {
          let hintMsg = this.autoCaptureHints[this.recentHint];
          // use a custom message for four corners not found
          if (this.recentHint === 'MITEK_ERROR_FOUR_CORNER') {
            hintMsg = this.customMessages[this.captureType].fourCornerMessage;
          }
          
          mitekScienceSDK.cmd('SHOW_HINT', hintMsg);
        }
      }
    } catch(error) {
      console.log("AutocaptureProcessingFeedback - " + error);
    }
  }

  /**
   * AutoCapture Frame Processing Started method
   * Triggers once when frames from live preview that undergo analysis has started. Use as signal for UX.
   * @param result
   */
  onAutoCaptureFrameProcessingStarted(result: any){
    console.log('started Auto capture>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    this.loading = false;
    let that = this;
    
    this.attemptTimer = setTimeout(function () {
      that.onAutoCaptureTimeout();
    }, AUTO_CAPTURE_TIME_OUT);
  }

  /**
   * AutoCapture Timeout method
   */
  onAutoCaptureTimeout(){
    console.log('Auto Timeout');
    clearTimeout(this.attemptTimer);
    mitekScienceSDK.cmd('SDK_STOP');
    
    this.misnapError = this.autoCaptureHints['CUSTOM_AUTO_TIMEOUT'];
    // this.initializeAndRunManualMode();
   
    try {
      this.onCameraTimeout.emit({'message' : this.misnapError });
    } catch(err) {
      console.error(err);
    }
  }

  /**
   * AutoCapture Frame Capture Result method
   * Triggers once the SDK has error/feedback and will return a failed image. Or, when the SDK successfully captures a
     valid image. Use result for remaining capture-flow.
   * @param result 
   */
  onAutoCaptureFrameCaptureResult(result: any){
    console.log('captured', result);
    console.log('captured Image ', result.response.imageData);
    clearTimeout(this.attemptTimer);
    clearTimeout(this.attemptTimer);
    
    if (result.response.status != 'failure') {
      this.processCapturedResult(result);
    }
  }

  /**
   * On SDK Error method
   * Triggers once when an error blocks a capture or analysis. Use to handle errors
   * @param err 
   */
  onAutoCaptureSdkError(err: any) {
    this.loading = false;
    clearTimeout(this.timer);
    console.log('auto capture error', err);
    if(err.code === 4006) {
      console.warn(`Invalid license, make sure you have placed the license
      provided under options.license property of the component preload`)
    }
    
    this.initializeAndRunManualMode();
  }

  /**
   * ManualCapture Frame Processing Feedback method
   * Triggers continuously until a successful capture. Use for hint-messages.
   * @param result 
   */
  onManualFrameProcessingFeedback(result: any){
    this.misnapError = '';
    let that = this;
    this.loading = false;
    
    setTimeout(function () {
      that.misnapError = that.manualCaptureHints[result.key];
    }, 1000);
  }

  /**
   * ManualCapture Frame Capture Result method
   * Triggers once the SDK has error/feedback and will return a failed image. Or, when the SDK successfully captures a
   * valid image. Use result for remaining capture-flow.
   * @param result 
   */
  onManualFrameCapturedResult(result: any) {
    if (this.captureMode == 'MANUAL_CAPTURE'){
      console.log('captured', result);
      console.log('captured Image ', result.response.imageData);
      
      this.loading = false;
      this.resultImage = result.response.imageData;
      
      if (result.response.status != 'failure') {
        this.processCapturedResult(result);
      }
    }
  }

  /**
   * ManualCapture Frame Processing Started method
   * Triggers once when frames from live preview that undergo analysis has started. Use as signal for UX.
   * @param result 
   */
  onManualFrameProcessingStarted(result: any){
    console.log('started Manual capture>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    this.loading = false;
  }

  /**
   * ManualCapture SDK Error method
   * Triggers once when an error blocks a capture or analysis. Use to handle errors
   * @param err 
   */
  onManualSdkError(err: any){
    console.log('manual capture error', err);
    
    if(err.code === 4006) {
      console.warn(`Invalid license, make sure you have placed the license
      provided under options.license property of the component preload`)
    }
    this.loading = false;
  }

  /**
   * Process Captured Result method
   * Process the captured result and emit the result to camera flow component
   * @param result 
   */
  processCapturedResult(result: any){
    this.misnapError = "";
    this.resultImage = result.response.imageData;
    
    let obj = {};
    obj['resultImage'] = this.resultImage;
    
    this.onCameraNext.emit(obj);
  }
}
