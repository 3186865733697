export const environment = {
  apiUrl: 'https://pa-intl-qa.fadv.net',
  production: false,
  camerasettings: {
    captureMode: 'Manual'
  },
  websdk_vendor: 'MITEK', // Possible values MITEK
  verification_api_vendor: 'MITEK',
  show_non_us_flow: 'Yes',
  havingTroubleToastConfig: {
    gotItButtonClicksThreshold: 5,
    idleTimeThresholdSeconds: 50
  },
  salesForceInitializeUrl: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
  salesForceChatSettings: {
    displayHelpButton: true,
    language: '',
    defaultMinimizedText: '',
    disabledMinimizedText: '',
    loadingText: '',
    storageDomain: '',
    prepopulatedPrechatFields: {},
    enabledFeatures: ['LiveAgent'],
    entryFeature: 'LiveAgent',
    initEnvUrl: 'https://firstadv--staging.sandbox.my.salesforce.com',
    initAgentUrl: 'https://firstadv--staging.sandbox.my.site.com',
    initParam1: '00D04000000qbtI',
    initAgentName: 'Chat_Team',
    customSettings: {
      baseLiveAgentContentURL: 'https://c.la12s-core1.sfdc-lywfpd.salesforceliveagent.com/content',
      deploymentId: '5725a000000Goie',
      buttonId: '5735a000000oLor',
      baseLiveAgentURL: 'https://d.la12s-core1.sfdc-lywfpd.salesforceliveagent.com/chat',
      eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I5a000000oLqdEAE_18831504012',
      isOfflineSupportEnabled: false
    }
  },
  salesForceChatEmbeddedServiceMinJS: 'https://firstadv--staging.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
  salesForceChatBaseUrl: 'https://service.force.com',
  salesForceChatPosition: 'left',
  default_chat_exp : 'SFC',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
