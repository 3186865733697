<!--The content below is only a placeholder and can be replaced.-->
<div class="container-fluid m-0 p-0 app-bar">
  <nav class="navbar navbar-light">
    <a class="navbar-brand logo marTop10">
     
    </a>
    <div id="langGlobe" class="dropdown" style="display:none;">
      <button class="btn btn-outline dropdown-toggle mt-n2" type="button" data-toggle="dropdown">
        <span class=""></span><img src="../../assets/img/lang/{{currentLang || 'en' }}.svg" />
        <span class="glyphicon glyphicon-triangle-bottom"></span>
      </button>
      <ul class="dropdown-menu dropdown-menu-right">
        <button *ngFor="let option of displayLanguage" class="dropdown-item" type="button" (click)="updateLanguage(option.languageCode)">
          <img class="mr-2 pb-1 align-middle" src="../../assets/img/lang/{{option.languageCode}}.svg" />{{option.languageName}}
        </button>
      </ul>
    </div>
  </nav>
</div>
<div class="container mt-3">
  <router-outlet></router-outlet>
</div>